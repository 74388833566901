.service-container {
  background-color: black;
  color: white;
  padding: 80px 20px;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;

  gap: 20px;
  height: fit-content;
}

.service-container .vertical-text {
  align-items: end;
  justify-content: center;
}

.text-content h1 {
  font-size: 40px;
  white-space: nowrap;
  font-weight: bold;
  letter-spacing: 3px;
  color: white;
  text-align: left;
  margin: 0;
  font-family: " Playfair Display", serif;
}

.service-container p {
  font-size: 1.2em;
  color: #83888d;
  max-width: 600px;
  text-align: justify;
  line-height: 1.4;
  margin-right: 0px;
  margin-top: 30px;
  font-weight: bolder;
}

.image-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-column: 3/4; 
  text-align: center;
  margin-left: -80px;
}

.party-image {
  max-width: 250px;
  border-radius: 10px;
  margin-bottom: 30px;
  grid-column: 3/4;
}

.btn-service {
  background-color: #cc0212;

  color: white;
  text-decoration: none;
  border-radius: 25px;
  border: 1px solid white;

  font-size: 0.8rem;
  padding: 8px 15px;
  cursor: pointer;
  font-weight: 300;
  text-transform: uppercase;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-service:hover {
  background-color: black;
}


@media (max-width: 1024px) {
  .service-container {
  
    gap: 0px;
  }

  .service-container .vertical-text h1 {
    writing-mode: vertical-rl;
    text-orientation: mixed;
   
  }

  .text-content h1 {
    font-size: 30px;
  }

  .party-image {
    max-width: 200px;
    margin-left: 0;
  }

  .service-container p {
    font-size: 18px;
   
    padding-right: 20px;
  }

  .image-button-container {
    margin-left: 0;
  }
}

/* Responsive styles for phones */
@media (max-width: 768px) {
  .service-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto; /* Stack items vertically */
    padding: 50px 45px;
    align-items: center;
    justify-content: center;
  }

  .service-container .vertical-text {
    align-items: center;
  }

  .service-container .vertical-text h1 {
    writing-mode: horizontal-tb;
    padding-bottom: 30px;
    font-size: 18px;
    letter-spacing: 5px;
    margin: 0;
  }

  .text-content {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    justify-content: center;
   
   margin: 0;
   padding-left: 20px ;
   padding-right: 20px;
   
  
  }

  .text-content h1 {
 
    text-align: center;
    letter-spacing: 2px;
    font-size: 26px;
  }

  .text-content p {
  
    font-size: 18px;
   padding-right: 0;
   
    text-align: justify;
  }

  .image-button-container {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    padding-right: 0;
    text-align: center;
  }

  .party-image {
    max-width: 40%;
    margin-left: 0;
  }
}