.contact-content {
  display: flex;
  align-items: center;
  min-height: 100vh;
  justify-content: center;
  text-align: center;
  background: url("../images/fire-background.webp");
  background-size: cover;
  background-position: center;
}
.contact-text {
  position: relative;
  text-align: left;
  max-width: 550px;
}

.h1-contact {
  margin: 0;

  text-align: center;
  margin-bottom: 15px;

  font-size: 40px;

  font-weight: bold;
  letter-spacing: 5px;
  color: white;

  font-family: " Playfair Display", serif;
}

.contact-element {
  padding: 15px;
  font-style: normal;
  margin-bottom: 10px;
  background-color: #4a4a5290;
  border-radius: 5px;
  font-size: 25px;
}

.contact-element a {
  text-decoration: none;
  color: white;
  transition: color 0.3s ease;
}
.contact-element a:hover,
.contact-element .icon:hover {
  color: black;
}
.contact-element .icon {
  margin-right: 10px;
  color: white;
  font-size: 30px;
  transition: color 0.3s ease;
}

@media (max-width: 768px) {
  .contact-content {
    flex-direction: column;
    padding: 25px;
  }

  .contact-text {
    text-align: center;
    max-width: 90%;
    margin-bottom: 150px;
  }

  .h1-contact {
    font-size: 1.8rem;
    letter-spacing: 3px;
  }

  .contact-element {
    font-size: 25px;
    padding: 12px;
    
  }

  .contact-element .icon {
    margin-right: 8px;
    font-size: 18px;
  }
}

/* Responsive Design for Phones */
@media (max-width: 480px) {
  .contact-content {
    flex-direction: column;
    padding: 25px;
  }

  .contact-text {
    text-align: center;
    max-width: 100%;

    margin-bottom: 150px;
  }

  .h1-contact {
    font-size: 24px;
    letter-spacing: 2px;
  }

  .contact-element {
    font-size: 20px;
    padding: 10px;
  }

  .contact-element .icon {
    font-size: 20px;
    margin-right: 5px;
  }
}
