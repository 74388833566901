.container-reservar {
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  
  text-align: center;
  background: url("../images/new-background-dark.webp") no-repeat center center;
  background-size: cover;
  color: white;
  font-size: 25px;
  display: flex;
  flex-direction: column;
}

.reservar-content h3 {
  margin: 0;
  text-align: center;
  margin-bottom: 15px;
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 5px;
  color: white;
  font-family: " Playfair Display", serif;
}

.reservas-number {
  background-color: #4a4a5290;
  display: inline-block;
  border-radius: 5px;
  padding: 15px;
  font-size: 25px;
  color: white;
 
}

.reservas-number a {
  color: white;
  margin-left: 10px;
  text-decoration: none;
  transition: color 0.3s ease;
}
.reservas-number .icon{
  transition: color 0.3s ease;
}
.reservas-number:hover,
.reservas-number a:hover {
  color: black;
}
@media (max-width: 768px) {
  .container-reservar {
    padding: 15px;
    font-size: 22px;
    
  }
.reservar-content{
  margin-bottom: 150px;
}
  .reservar-content h3 {
    font-size: 32px;
    letter-spacing: 3px;
  }

  .reservas-number {
    font-size: 25px;
    padding: 8px 16px;
  }
}

/* Responsive Design for Phones */
@media (max-width: 480px) {
  .container-reservar {
    padding: 10px;
   
  }

  .reservar-content h3 {
    font-size: 24px;
    letter-spacing: 2px;
  }

  .reservas-number {
    font-size: 20px;
    padding: 6px 12px;
    margin-top: 15px;
  }
}