.reservarMain-container {
  padding: 80px 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  align-items: center;
  text-align: center;
  color: white;
  background: url("../images/new-background-dark.webp");
  background-size: cover;
  background-position: center;
}

.reservarMain-container .vertical-text {
  align-items: end;
  justify-content: center;
}

.reservarMain-title {
  grid-column: 2 / 3;

  white-space: nowrap;

  padding-bottom: 80px;
}

.reservarMain-title h1 {
  font-size: 40px;
  letter-spacing: 3px;
  font-weight: bold;
  font-family: " Playfair Display", serif;
  color: white;
}
.reservarMain-container .btn {
  grid-column: 2 / 3;

  max-width: 150px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #cc0212; /* Button color */
  color: white;
  text-decoration: none;
  border-radius: 25px;
  border: 1px solid white;
  font-size: 1.2rem;
  margin-top: -100px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.reservarMain-container .btn:hover {
  background-color: black;
}

@media (max-width: 1024px) {
  .reservarMain-container .vertical-text h1 {
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }

  .reservarMain-title {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }
  .reservarMain-title h1 {
    font-size: 30px;
  }
}

/* Media Query for Mobile Devices (up to 768px) */
@media (max-width: 768px) {
  .reservarMain-container {
    grid-template-columns: 1fr;
    padding: 50px 15px;
    grid-template-rows: auto auto auto;
  }

  .reservarMain-title h1 {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    writing-mode: horizontal-tb;
    text-orientation: initial;
    margin: 0;
    text-align: center;
    font-size: 30px;
    letter-spacing: 2px;
  }
  .reservarMain-container .vertical-text {
    align-items: center;
  }

  .reservarMain-container .vertical-text h1 {
    writing-mode: horizontal-tb;
    padding-bottom: 30px;
    font-size: 18px;
    letter-spacing: 5px;
    margin: 0;
  }
  .reservarMain-title {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
   white-space:inherit;
    text-align: center;
    padding-bottom: 10px;
  }
.reservarMain-title h1{
  letter-spacing: 2px;
  font-size: 26px;
}
  .reservarMain-container .btn {
    grid-column: 1 / 2;
    grid-row: 3 / 4;
    font-size: 1rem;
    margin-top: 10px;
    max-width: 20%;
    width: 100%;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;

  }
}
