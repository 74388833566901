.about-container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr 1fr;
  padding: 80px 20px;
  border-bottom: 1px solid #83888d;
  color: #83888d;
  background: black;
  position: relative;
}

.km0-section {
  display: flex;
  gap: 180px;
  align-items: center;
  justify-content: center;
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}
.years-section {
  display: flex;
  gap: 180px;
  align-items: center;
  justify-content: center;
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  padding-top: 100px;
}

.about-container h1 {
  font-size: 40px;
  font-weight: bold;
  letter-spacing: 3px;
  color: white;
  text-align: left;
  font-family: " Playfair Display", serif;
}

.km0-text,
.years-text {
  max-width: 400px;
  line-height: 1.4;
  text-align: justify;
  font-size: 1.2em;
  font-weight: bolder;
}

.km0-image,
.years-image {
  max-width: 300px;
  border-radius: 10px;
}

.km0-section .km0-text {
  justify-self: start;
}

.km0-section .km0-image {
  justify-self: end;
}

.years-section .years-image {
  justify-self: start;
}

.years-section .years-text {
  justify-self: end;
}
 .vertical-text {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  display: flex;
  align-items: center;
 justify-content: center;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  height: 100%;
  color: #fff;
  position: relative;
 
}
 .vertical-text h1 {
  font-size: 20px;
  font-weight: 300;
  margin-top: -50px;
  letter-spacing: 10px;
  font-family: " Playfair Display", serif;
}
@media (max-width: 1024px) {

  .years-section,
  .km0-section{
    gap: 80px;
    padding-left: 30px;
    padding-right: 30px;
  }
 
  .about-container .vertical-text h1 {
    writing-mode: vertical-rl;
    text-orientation: mixed;
   
    font-size: 20px;
   margin: 0;
   
   
  }
  
  .km0-section h1,
  .years-section h1 {
    font-size: 30px;
  }


  .about-container p {
    font-size: 18px;
   
  }
 
 
}

@media (max-width: 768px) {
  .about-container {
    display: flex;
    flex-direction: column; 
    padding: 50px 15px;
  
  }

  .about-container .vertical-text {
    align-items: center;
    max-height: fit-content;
   
  }
  .about-container .vertical-text h1{
    writing-mode: horizontal-tb;
    padding-bottom: 30px;
    font-size: 18px;
    letter-spacing: 5px;
    
  }
  .km0-section,
  .years-section {
    display: flex;
    flex-direction: column; /* Stack text and image vertically */
    align-items: center;
    text-align: center;
    gap: 30px; /* Reduce gap between items */
    
  }

  .km0-text,
  .years-text {
    font-size: 18px;
   
    max-width: 600px;
    text-align: justify; 
  }


  
  .km0-section h1,
  .years-section h1 {
    font-size: 30px;
    margin-bottom: 15px; 
    text-align: center;
    margin-top: 0;

  }
.years-section{
  flex-direction: column-reverse;
}
  .about-container img {
    max-width: 60%; 
  }

  .km0-section img{
    max-width: 50%;
  }
}