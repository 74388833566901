body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
 
}
html {
  scroll-behavior: smooth;
}
.container {
  max-width: 100%;
  overflow-x: hidden; /* Ensure no overflow */
  padding: 0;
  margin: 0;
}
