.galleryPreview-container {
  display: grid;
  grid-template-columns:auto 1fr;;
  gap: 20px;
  padding: 80px 20px;
  height: fit-content;
  background: url(../images/fire-background.jpg);
  background-size: cover;
  background-position:center;
  border-bottom: 1px solid #83888d;
}

.galleryPreview-container .vertical-text {
  align-items: end;
}

.gallery-images {
  display: flex;
  justify-self: center;
 
  flex-wrap: nowrap;
  gap: 15px;
}

.gallery-images img {
  width: 150px;
  height: 200px;
  object-fit: cover;
  border-radius: 5px;
  transition: transform 0.3s ease;
}

.gallery-images img:hover,
.see-more:hover {
  transform: scale(1.1);
}

.see-more {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(../images/tarta.jpg);
  background-size: cover;
  background-position: center;
  color: white;
  font-size: 25px;
  border: none;
  text-decoration: none;
  border-radius: 5px;
  transition: transform 0.3s ease;
  width: 125px;
  height: 170px;
}

@media (max-width: 1024px) {


  .galleryPreview-container .vertical-text h1 {
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }

  .gallery-images img {
    width: 130px;
    height: 180px;
  }

  .see-more {
    width: 80px;
    height: 155px;
    font-size: 20px;
  }
}

/* Phone Styles */
@media (max-width: 768px) {
  .galleryPreview-container {
    grid-template-columns: 1fr; /* Stack vertical text and images */
    grid-template-rows: auto auto;
    padding: 50px 15px;
  }

  .galleryPreview-container .vertical-text h1 {
    grid-column: 1;
    grid-row: 1;

    text-align: center;
    align-items: center;
    justify-content: center;
   
    margin: 0 auto 20px auto; /* Center text with margin */
  }
  .galleryPreview-container .vertical-text h1 {
    writing-mode: horizontal-tb;
    font-size: 18px;
    letter-spacing: 5px;
  }
  .gallery-images {
    grid-column: 1;
    grid-row: 2;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-left: 0;
  }

  .gallery-images img {
    width: 50%; /* Adjust width for better fit on phones */
    height: auto;
    margin-bottom: 10px;
  }

  .see-more {
    width: 45%; /* Adjust button width */
    height: 90pxpx;
    font-size: 22px;
  }
}
