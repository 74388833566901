/* Base styles for navbar */
.navbar {
  background-color: rgba(0, 0, 0, 0.702);
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

/* Menu toggle button */
.menu-toggle {
  border: none;
  cursor: pointer;
  color: white;
  font-size: 1.5rem;
  display: none;
  margin: 0 auto;
}

/* Navbar list styles */
.navbar-list {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  flex: 1;
}

/* Navbar links */
.navbar-list .nav-link {
  padding: 15px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.navbar-list .nav-link.active {
  background-color: rgba(126, 122, 122, 0.472);
}

/* Navbar list items */
.navbar-list li {
  margin-right: 20px;
}

.navbar-list li:first-child {
  margin-left: 0;
}

.navbar-list li:last-child {
  margin-right: 0;
}

/* Link styles */
.navbar-list li a {
  text-decoration: none;
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  padding: 10px;
  transition: color 0.3s ease, background-color 0.3s ease;
}

.navbar-list li a:hover {
  color: #cc0212;
}

/* Specific styles for menu link */
#menu-link {
  background-color: #a60218;
  border-radius: 5px;
  transition: background-color 0.4s ease;
}

#menu-link:hover {
  background-color: black;
  color: white;
}

/* Styles when navbar list is active */
.navbar-list.active {
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.856);
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 20px 0;
}

/* Responsive styles for mobile */
@media (max-width: 900px) {
  .menu-toggle {
    display: block; /* Show menu toggle button on mobile */
    font-size: 2rem;
  }

  .navbar {
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
  }

  .navbar-list {
    display: none; /* Hide menu list by default */
  }

  .navbar-list.active {
    display: flex;
    position: relative;
  }

  #menu-link {
    background-color: transparent;
  }

  #menu-link:hover {
    background-color: transparent;
  }
}

@media (max-width: 768px) {
  .navbar-list {
    display: none; /* Hide menu list by default */
  }

  .navbar-list.active {
    display: flex;
    flex-direction: column;
  }

  .navbar-list li {
    margin: 10px 0;
  }

  .menu-toggle {
    display: block; /* Ensure menu toggle button is visible */
  }

  .menu-link {
    background-color: transparent;
    color: #cc0212;
  }

  .menu-link:hover {
    background-color: transparent;
  }
}
