.policy-content{
    display: flex;
    min-height: 100vh; 
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: black;
    padding: 2rem;
    color: white;
  }
  

  .policy-content h2 {
    margin-top: 70px;
    border-bottom: 2px solid #333;
    padding-bottom: 0.5em;
    margin-bottom: 1em;
    color: #fff;
}

.policy {
    margin-bottom: 2em;
    width: 100%;
    max-width: 800px;
}

.policy h3 {
    color: #fff;
    background-color: #333;
    padding: 0.5em;
    border-radius: 5px;
}

.policy p, .policy ul {
    margin: 0.5em 0;
    text-align: justify;
}

.policy ul {
    list-style-type: none;
    padding-left: 1.5em;
}



.policy a {
    color:#cc0212;
    text-decoration: none;
}

.policy a:hover {
    color: #333;
}

@media (max-width: 768px) {
    .policy {
        font-size: 16px;
    }

    .policy-content h2 {
        margin-top: 30px;
    }
}

@media (max-width: 480px) {
    .policy {
        font-size: 14px;
    }
}