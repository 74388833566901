/* Base styles for gallery page */
.page-galeria {
  padding: 30px;
  text-align: center;
  font-size: 20px;
  background-color: black;
  color: white;
}

.container-galeria {
  margin-top: 70px;
}

h1 {
  margin-bottom: 30px;
  font-size: 2rem;
  color: white;
}


.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 25px;
  margin-bottom: 50px; /* Ensure proper spacing below the grid */
}


.gallery-item {
  min-height: 200px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}


.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

/* Hover effect for images */
.gallery-item img:hover {
  transform: scale(1.1);
}

/* Responsive styles for medium screens */
@media (max-width: 1024px) {
  .page-galeria {
    padding: 20px;
  }

  .gallery-grid {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  h1 {
    font-size: 1.5rem;
  }
}

/* Responsive styles for small screens */
@media (max-width: 768px) {
  .page-galeria {
    padding: 15px;
  }

  .gallery-grid {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  }

  h1 {
    font-size: 1.2rem;
  }
}
