button {
    
    background-color: rgb(54, 74, 46) ;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    
  }
  
  button:hover {
    background-color: black;
  }

  body{
    font-family: "Lato", sans-serif;
    font-weight:300;
   
  }

  .divider {
    height: 2px; 
    background-color: white; 
    margin: 0; 
  }