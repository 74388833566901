/* Container for the text */
.scrolling-container {
    width: 100%; /* Full width of the screen */
    overflow: hidden; /* Hide the text that moves off-screen */
    position: absolute;
    top: 10%;
    visibility: hidden;
    display: flex;
    justify-content: center; /* Center the text horizontally */
  }
  
  .scrolling-text {
    display: flex;
    animation: scroll-left 15s linear infinite; /* Animation to scroll the text */
    white-space: nowrap; /* Keep the text on a single line */
    font-size: 1.5rem; 
    text-transform: uppercase; /* Uppercase text */
    color: white; /* White text */
    letter-spacing: 2px; /* Space between letters */
  }
  
  /* Keyframe for scrolling the text from right to left */
  @keyframes scroll-left {
    0% {
      transform: translateX(100%); /* Start from the right, off-screen */
    }
    100% {
      transform: translateX(-100%); /* Move the text completely off the left side */
    }
  }
  