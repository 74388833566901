.menu {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("../images/carne-oscuro.jpg");
  background-size: cover;
  background-position: center;
  text-align: center;
  position: relative;
  color: white;
  
}

.buttons-container {
  display: flex;
  gap: 20px;
}

.flag-button {
  padding: 15px;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.flag-button img {
  width: 80px;
  height: auto;
  transition: transform 0.3s ease;
}

.flag-button:hover {
  transform: scale(1.1);
}

.flag-button:hover img {
  transform: scale(1.15);
}

@media (max-width: 768px) {
  .menu {
   
    height: 100vh;
  }

  .buttons-container {
    flex-direction: column;
    gap: 15px;
  }

  .flag-button img {
    width: 80px;
  }
}

@media (max-width: 480px) {
  

  .buttons-container {
    gap: 10px;
  }

  .flag-button {
    padding: 10px;
  }

  .flag-button img {
    width: 80px;
  }
}
