.hero {
  height: 100vh;
  background: url("./images/bbq-background-meat-lessdark.webp")no-repeat center center;
  background-size: cover;
  background-position: center;
 
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  max-width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.hero-content {
  max-width: 800px;
  padding: 20px;
  margin: 0 auto;
}

.highlight {
  font-size: 4rem;
  font-family: " Playfair Display", serif;
  text-shadow: 5px 3px 0 black;
  font-weight: 900;
  font-style: italic;
}

.hero-content h1 {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-size: 35px;
  margin-bottom: 20px;
  transition: font-size 0.3s ease;
}

.btn {
  display: inline-block;
  background-color: #cc0212;
  color: white;
  border-radius: 25px;
  border: 1px solid white;
  text-align: center;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 15px;
  cursor: pointer;
  font-weight: 300;
  text-transform: uppercase;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn:hover {
  background-color: black;
}

/* Arrow */
.center-con {
  display: flex;
  flex-direction: column; 
  align-items: center;
  position: absolute;
  bottom: 20px;
 cursor: pointer;
}

.round {
  position: relative;
  border: 1.7px solid #fff; 
  width: 60px;
  height: 60px; 
  border-radius: 100%;
  margin-top: 20px;
}

.arrow {
  z-index: 999;
  height: 1px; 
  width: 20px; 
  background: #fff;
  transition: 0.4s ease;
}

.arrow:first-child {
  display: block;
  position: absolute;
  transform: rotate(45deg);
  left: 23%;
  bottom: 33%;
}

.arrow:nth-child(2) {
  display: block;
  position: absolute;
  transform: rotate(-45deg);
  left: 45%;
  bottom: 33%;
}

.arrow:nth-child(3) {
  display: block;
  position: absolute;
  transform: rotate(45deg);
  left: 23%;
  bottom: 54%;
}

.arrow:nth-child(4) {
  display: block;
  position: absolute;
  transform: rotate(-45deg);
  left: 45%;
  bottom: 54%;
}

.round:hover .arrow:nth-child(1) {
  transform: rotate(-135deg);
}

.round:hover .arrow:nth-child(2) {
  transform: rotate(135deg);
}

.round:hover .arrow:nth-child(3) {
  transform: rotate(225deg);
}

.round:hover .arrow:nth-child(4) {
  transform: rotate(-225deg);
}

@media (max-width: 940px) {
  .hero {
    height: 70vh;
  }

  .hero-content {
    padding: 15px;
  }

  .hero-content h1 {
    font-size: 3.2rem;
  }

  .highlight {
    font-size: 5rem;
  }

  .btn {
    padding: 12px 24px;
    border: 1px solid white;
    font-size: 1.7rem;
  }
}

@media (max-width: 768px) {
  .hero {
    height: 70vh;
    max-width: 100%;
  }

  .highlight {
    font-size: 3.5rem;
  }

  .hero-content {
    padding: 20px;
  }

  .hero-content h1 {
    font-size: 2rem;
    margin-bottom: 15px;
  }

  .btn {
    padding: 7px 12px;
    font-size: 1rem;
  }

  .arrow {
   
    height: 1px; 
    width: 15px; 
  }

  
.round {
border: 1.5px solid white;
  width: 45px;
  height: 45px; 
}

}
