.language-switcher {
  position: relative;

  display: inline-block;
}

.dropdown-toggle {
  background:none;
  
  border: none;
  cursor: pointer;
  padding: 1px;
  padding-bottom: 0px;
  position: relative;
  bottom: 9px;
  
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dropdown-toggle:hover {
  background-color:#a60218;
  color: white;
}
.language-icon {
  width: 30px;
  padding: 3px;
  height: auto;
  color: white;
  position: relative;

}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: rgba(255, 255, 255, 0.566);
  border: 1px solid #000000;
  border-radius: 5px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 80px;
}

.dropdown-menu button {
  background-color: rgba(255, 255, 255, 0.337);
  color: #143b59;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
  text-align: center;
  margin: 5px;
  width: 80%;

  transition: background-color 0.3s ease, color 0.3s ease;
}

.dropdown-menu button:hover {
  background-color: black;
  color: white;
}

.dropdown-menu button:focus {
  outline: none;
}
@media (max-width: 900px) {
  .menu-toggle {
    display: block;
    
  }
.dropdown-toggle{
  position: relative;
  left: 0px;
}
  .navbar {
    flex-direction: column;
    align-items: center;
  
  }

  .navbar-list {
    display: none;
    width: 100%;
    justify-content: center;
    position: absolute;
    top: 50px;
    left: 0;
    padding: 10px 0;
    z-index: 1000;
  }

  .navbar-list.active {
    display: flex;
    flex-direction: column;
  }

  .navbar-list li {
    width: 100%;
    margin: 0;
    text-align: center;
  }

  .navbar-list li a {
    display: block;
  }

  .language-switcher {
    order: 1;
    width: 100%;
  }

  .language-switcher .dropdown-menu {
    display: flex;
    top: 110%;
    left: 25%;
    width: 50%;
  }
  .dropdown-menu button {
    text-align: center;
    justify-content: center;
    padding: 3px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
